import { Banner, BREAKPOINTS, COLORS, LoadingIndicator, PageWidth, Size, ComponentM, ComponentTextStyle, ComponentS, ComponentXL, BackButton, DropdownButton, ModalDialog, ComponentL, ToastContext, Button, SystemIcons, HorizontalCard } from '@laerdal/life-react-components';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CaseDetailsDto } from '../../model/dto/cases/caseDetailsDto';
import CaseApi from '../../services/api/CaseApi';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import moment from 'moment';
import { AttachmentDto, CaseEmailDto } from '../../model/dto/cases/caseEmailDto';
import CaseEmail from './CaseEmail';
import CaseStatus from './CaseStatus';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../store/account/accountSlice';
import { StyledPageWidth } from '../_commonComponents/StyledComponents';
import CreateComment from './CreateComment';
import { CaseStatuses } from '../../model/constants/CaseStatuses';
import React from 'react';
import { useMediaMatch } from 'rooks';
import { FailToastOptions } from '../../model/constants/ToastConstants';

const Title = styled(ComponentM)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  flex-grow: 1;
`;

const SecondColoumTitle = styled.div`
  padding: 12px 0 8px 0;
`;

const RowWrapper = styled.div`
  display:flex;
  justify-content: space-between;
`;
const CenterRowWrapper = styled.div`
  display:flex;
  justify-content: center;
`;

const Columns = styled.div`
  display: flex;
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  flex-direction: column;
  gap: 32px;
  margin-top:32px;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0px 24px ;
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.white};

  
  ${BREAKPOINTS.LARGE}{
    flex: 0 0 400px;
  }
`;

const TitleComponent = styled.div`
  display: flex;
  
  padding: 24px 0;
  align-items: center;
  align-self: stretch;
`;

const SecondColumn = styled.div`
  display:flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 0 24px 15px 24px ;
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.white};
`;

const CaseDetails = styled.div`
  
`;

const Description = styled.div`
  display:flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 8px;
  overflow-wrap: anywhere;
`;


const PropertyLine = styled.div`
  display:flex;
  justify-content: space-between;
  align-self: stretch;
  padding: 8px 0;
  overflow-wrap: anywhere;
`;

const Divider = styled.div`
  margin:  32px 0;
  border-bottom: 1px solid ${COLORS.neutral_100};
  align-self: stretch;
`;


const AttachmentsList = styled.div`
  display:flex;
  flex-direction:column;
  gap: 16px;
  padding:0 10px 16px 10px;
`;


const AttachmentContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: normal;
  gap: 16px;
  align-self: stretch;
  background: ${COLORS.white};
  
  height: 500px;
  embed {
    height: 100%;
  }
`;

const OriginalMailFrom = styled(ComponentS)`
  margin:  10px 0 16px 0;
`;
const CenterComponentS = styled(ComponentS)`
  align-self: center;
`;


const CaseDetailsPage = () => {
  const {addToast} = useContext(ToastContext);
  const navigate = useNavigate();
  const { caseNumber } = useParams<{ caseNumber: string }>();
  const user = useSelector(selectUserProfile);
  const { t, i18n } = useTranslation('Cases');

  const [loading, setLoading] = useState<boolean>(true);
  const [emailSortDescending, setEmailSortDescending] = useState<boolean>(true);
  const [caseDetails, setDetailsCase] = useState<CaseDetailsDto | undefined>(undefined);
  const [historySort, setHistorySort] = useState<string[]>([t('Most recent')]);

  const [emailsLoading, setEmailsLoading] = useState<boolean>(true);
  const [caseEmails, setEmailsCase] = useState<CaseEmailDto[] | undefined>(undefined);

  const [allAttachmentsModalOpen, setAllAttachmentsModalOpen] = useState<boolean>(false);
  

  const [attachmentPreviewModalOpen, setAttachmentPreviewModalOpen] = useState<boolean>(false);
  const [attachmentPreviewModalLoading, setAttachmentPreviewModalLoading] = useState<boolean>(false);
  const [attachmentPreviewModalData, setAttachmentPreviewModalData] = useState<AttachmentDto | undefined>(undefined);
  const [attachmentPreviewLink, setAttachmentPreviewLink] = useState<string>('');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  
  const [lastCommentHiddenId, setlastCommentHiddenId] = useState<string>('');

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  
  useEffect(() => {
    CaseApi.GetCaseDetails(caseNumber!, i18n.language).then((response) => {
      setDetailsCase(response);
      setLoading(false);
    });
    CaseApi.GetCaseEmails(caseNumber!).then((response) => {
      setEmailsCase(response);
      setEmailsLoading(false);
    });
  }, []);

  const previewAttachment = (attachment: AttachmentDto) => {
    if(attachment.fileExtension.toLowerCase() !== 'pdf' && !['jpg', 'jpeg', 'png', 'gif'].includes(attachment.fileExtension.toLowerCase())) {
      downloadAttachment(attachment);
      return;      
    }

    setAttachmentPreviewModalLoading(true);
    setAttachmentPreviewModalData(attachment);
    setAttachmentPreviewModalOpen(true);
    
    CaseApi.GetBlobLink(caseNumber!, attachment.fileIdentifier, attachment, (link) => { setAttachmentPreviewLink(link) }, () => {})
      .finally(() => setAttachmentPreviewModalLoading(false));
  };

  const downloadAttachment = (attachment: AttachmentDto) => {
        
    //@ts-ignore
    posthog.capture?.('CaseDetails DownloadAttachment');
    
    CaseApi.DownloadAttachment(caseNumber!, attachment.fileIdentifier, attachment, () => {});
  };

  const formatDate = (date?: string) => {
    return date ? moment(date).format('LL') : ' ';
  };

  const addComment = (comment: string, files: File[]) => {
    const hiddenId = `<div style="display:none">${crypto.randomUUID()}</div>`

    CaseApi.CreateComment({
      caseNumber: caseNumber ?? '',
      comment: comment + hiddenId
    }, files).catch((e)=>{
      try{
        if(e?.response?.data?.errors[""][0].includes("Request body too large")){
          //@ts-ignore
          posthog.capture?.('CreateComment ErrorFilesToLarge', e);
          addToast(t('Files too large! Maximum is 30MB'), FailToastOptions)
        }
        else{
          //@ts-ignore
          posthog.capture?.('CreateComment Error', e);
          addToast(t('Error when adding a reply'), FailToastOptions)
        }
      }
      catch
      {
        //@ts-ignore
        posthog.capture?.('CreateComment Error', e);
        addToast(t('Error when adding a reply'), FailToastOptions)
      }

      const emailsWithoutCreated = caseEmails?.filter(email => !email.htmlBody?.includes(lastCommentHiddenId));
      setEmailsCase(emailsWithoutCreated);
    })
    

    setlastCommentHiddenId(hiddenId);

    //@ts-ignore
    posthog.capture?.('CaseCommentAdded', {
      caseNumber: caseNumber,
      attachmetsCount: files?.length
    });

    setEmailsCase([...caseEmails ?? [], {
      htmlBody: comment,
      messageDate: new Date(),
      fromName: user?.firstName + ' ' + user?.lastName,
      attachments: files.map(file => ({
        fileName: file.name,
        fileExtension: file.name.split('.').pop() || '',
        fileIdentifier: '',
        uploading: true
      })),
      justCreatedWithFiles: true
    }]);

    intervalRef.current = setInterval(pollForNewEmails, 10000);
  };
  
  const pollForNewEmails = useCallback(() => {
    CaseApi.GetCaseEmails(caseNumber!)
      .then((emails) => {
        const newEmailFound = emails.some(email =>           
          email.htmlBody?.includes(lastCommentHiddenId)
        );

        if (newEmailFound) {
          console.log('newEmailFound', emails);

          setEmailsCase(emails);
          //stopPolling();
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
        }
      })
  }, [caseNumber, lastCommentHiddenId]);

  return (
    <>
      <Helmet>
        <title>{t('Case details page')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>

        <ModalDialog
          isModalOpen={attachmentPreviewModalOpen}
          closeModalAndClearInput={ () => setAttachmentPreviewModalOpen(false) }
          closeAction={() => setAttachmentPreviewModalOpen(false)}
          submitAction={(e) => e?.preventDefault()}
          size={ isMediumScreen ? Size.Large : Size.Small}
          title={ attachmentPreviewModalData?.fileName ?? ''}
          contentOverflow={'auto'}
          buttons={[
            {
              id: 'download',
              variant: 'tertiary',
              text: t('Download'),
              action: () => {
                if(attachmentPreviewModalData) 
                  downloadAttachment(attachmentPreviewModalData);

                setAttachmentPreviewModalOpen(false);
              },
              disabled: attachmentPreviewModalLoading,
              type: 'button',
            },
            {
              id: 'close',
              variant: 'primary',
              type: 'button',
              text: t('Close'),
              action: () => {
                setAttachmentPreviewModalOpen(false);
              },
            },
          ]}>
          <AttachmentContainer>
            {attachmentPreviewModalLoading &&
              <LoadingIndicator />
            }

            { !attachmentPreviewModalLoading && !!attachmentPreviewLink && attachmentPreviewModalData?.fileExtension &&
              ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(attachmentPreviewModalData.fileExtension.toLowerCase()) &&
              <img src={attachmentPreviewLink} alt={attachmentPreviewModalData.fileName} />
            }

            {!attachmentPreviewModalLoading && !!attachmentPreviewLink && attachmentPreviewModalData?.fileExtension?.toLowerCase() === 'pdf' &&
              <embed src={attachmentPreviewLink} type="application/pdf" width="100%" height="600px" />
            }
          </AttachmentContainer>
        </ModalDialog>

        <ModalDialog
          isModalOpen={allAttachmentsModalOpen}
          closeModalAndClearInput={ () => setAllAttachmentsModalOpen(false) }
          closeAction={() => setAllAttachmentsModalOpen(false)}
          submitAction={(e) => e?.preventDefault()}
          size={ isMediumScreen ? Size.Large : Size.Small}
          title={ t('Attachments')}
          contentOverflow={'auto'}
          buttons={[
            {
              id: 'close',
              variant: 'primary',
              type: 'button',
              text: t('Close'),
              action: () => {
                setAllAttachmentsModalOpen(false);
              },
            },
          ]}>
          <AttachmentContainer>
            <ComponentM>{t('All attachments for this case are listed below.')}</ComponentM>
            
            {caseEmails && caseEmails.length > 0 && (
              <AttachmentsList>
                {caseEmails.flatMap(email => 
                  email.attachments.map(attachment => (
                    <HorizontalCard 
                      key={`${email.messageDate}-${attachment.fileName}`}
                      variant="elevated"
                      title={attachment.fileName} 
                      description={attachment.fileExtension} 
                      actions={attachment.uploading ? [{
                              componentType: "custom",
                              content: <LoadingIndicator size={Size.Medium} />
                          }] : [{
                              componentType: 'icon',
                              action: () => {
                                downloadAttachment!(attachment)
                              },
                              icon: <SystemIcons.Download color={COLORS.primary_600} />
                          }]}
                      action={ () =>  {
                        setAllAttachmentsModalOpen(false);
                        previewAttachment!(attachment)}
                      } />
                  ))
                )}
              </AttachmentsList>
            )}
          </AttachmentContainer>
        </ModalDialog>

        <BackButton size={Size.Small} onClick={() => navigate('/cases')}>{t('All Support Cases')}</BackButton>

        {loading && <LoadingIndicator />}
        
        {caseDetails &&
          <CaseDetails>
            <ComponentXL textStyle={ComponentTextStyle.Bold} >{caseDetails.subject}</ComponentXL>

            <Columns>
              <FirstColumn>
                <TitleComponent>
                  <Title textStyle={ComponentTextStyle.Bold}>{t('Case details')}</Title>
                  <CaseStatus status={caseDetails.status} statusTranslated={caseDetails.statusTranslated} />
                </TitleComponent>

                {caseDetails.description &&
                  <Description>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Description')}</ComponentS>
                    <ComponentM textStyle={ComponentTextStyle.Regular} >
                      {caseDetails.description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </ComponentM>
                  </Description>
                }

                <Divider />

                {
                  caseDetails.caseNumber &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Case number')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.caseNumber}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.caseType &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Case type')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.caseType}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.caseOrigin &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Case origin')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.caseOrigin}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.dateOpened &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Date created')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{formatDate(caseDetails.dateOpened)}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.dateClosed &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Date closed')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{formatDate(caseDetails.dateClosed)}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.supportReason &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Reason')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.supportReason}</ComponentS>
                  </PropertyLine>
                }

                <Divider />

                {
                  caseDetails.accountNumber &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Account number')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.accountNumber}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.accountName &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Account name')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.accountName}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.contactName &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Created by')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.contactName}</ComponentS>
                  </PropertyLine>
                }

                

                {
                  caseDetails.customerAssetName &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Asset')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.customerAssetName}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.productNumber &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Product number')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.productNumber}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.productName &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Product name')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.productName}</ComponentS>
                  </PropertyLine>
                }

                {
                  caseDetails.serialNumber &&
                  <PropertyLine>
                    <ComponentS textStyle={ComponentTextStyle.Bold} >{t('Serial number')}</ComponentS>
                    <ComponentS textStyle={ComponentTextStyle.Regular} >{caseDetails.serialNumber}</ComponentS>
                  </PropertyLine>
                }

                
                {caseEmails && caseEmails.some(email => email.attachments && email.attachments.length > 0) && (
                  
                  <PropertyLine>
                    <CenterComponentS textStyle={ComponentTextStyle.Bold}  >{t('Case attachments')}</CenterComponentS>
                    <Button variant='tertiary' size={Size.Small} icon={<SystemIcons.Attachment />} onClick={() => setAllAttachmentsModalOpen(true)}>{t('View all')}</Button>
                  </PropertyLine>
                )}
              </FirstColumn>

              <SecondColumn>
                <SecondColoumTitle>
                  <RowWrapper>
                    <OriginalMailFrom>
                      <ComponentM textStyle={ComponentTextStyle.Bold}>{t('History')}</ComponentM>
                    </OriginalMailFrom>

                    {!!caseEmails && caseEmails.length > 0 &&
                      <DropdownButton
                        type={'text'}
                        onClick={(value) => {
                          //@ts-ignore
                          posthog.capture?.('CaseDetailsPage HistoryOrder', {
                            order: value[0]
                          });
                          setHistorySort([value[0]])
                          setEmailSortDescending(value[0] == t('Most recent'));
                        }}
                        items={[{ value: t('Most recent') }, { value: t('Oldest') }]}
                        value={historySort} />
                    }

                  </RowWrapper>
                </SecondColoumTitle>

                {
                  caseDetails.status != CaseStatuses.Resolved &&
                  !!caseDetails.accountNumber &&
                  !emailsLoading &&
                  <CreateComment
                    busy={caseEmails?.some(email => email.justCreatedWithFiles) ?? false}
                    initials={`${user?.firstName[0]}${user?.lastName[0]}`}
                    save={(comment, files) => addComment(comment, files)} />
                }

                {!!caseDetails.resolution && emailSortDescending &&
                  <CaseEmail emailData={{
                    fromName: caseDetails.casePrimaryOwner,
                    messageDate: new Date(caseDetails.dateClosed ?? ''),
                    textBody: t('marked this case as resolved') + "\n" + caseDetails.resolution,
                    attachments: []
                  }} />
                }

                {emailsLoading &&
                  <CenterRowWrapper>
                    <LoadingIndicator />
                  </CenterRowWrapper>
                }

                <>
                  {!!caseEmails
                    && caseEmails.sort((a, b) =>
                      emailSortDescending ?
                        (new Date(b.messageDate)).getTime() - (new Date(a.messageDate)).getTime() :
                        (new Date(a.messageDate)).getTime() - (new Date(b.messageDate)).getTime()
                    )
                      .map(c => <CaseEmail 
                                  openAttachment={previewAttachment} 
                                  downloadAttachment={downloadAttachment} 
                                  emailData={c} 
                                  key={c.messageDate.toString()} />)}
                </>

                {!!caseDetails.resolution && !emailSortDescending &&
                  <CaseEmail emailData={{
                    fromName: caseDetails.casePrimaryOwner,
                    messageDate: new Date(caseDetails.dateClosed ?? ''),
                    textBody: t('marked this case as resolved') + "\n" + caseDetails.resolution,
                    attachments: []
                  }} />
                }

                {!emailsLoading && caseEmails?.length == 0 &&
                  <ComponentM textStyle={ComponentTextStyle.Regular}>{t('No email communication')}</ComponentM>
                }
              </SecondColumn>
            </Columns>
          </CaseDetails>
        }
      </StyledPageWidth>
    </>);
};

export default CaseDetailsPage;