import { BREAKPOINTS, Button, COLORS, ComponentM, ComponentS, ComponentSStyling, ComponentTextStyle, HorizontalCard, LoadingIndicator, ProfileButton, Size, SystemIcons, Tag, TooltipWrapper } from "@laerdal/life-react-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AttachmentDto, CaseEmailDto } from "../../model/dto/cases/caseEmailDto";
import { Ref, createRef, useLayoutEffect, useState } from "react";
import React from "react";
import dompurify from "dompurify";
import { useMediaMatch } from "rooks";

const CreateIcon = styled.div`
  pointer-events: none;

  
  display: block;
`;


export const EmailCard = styled.div`
  position: relative;
  overflow: hidden;

  
  background-color: ${COLORS.white};
  padding: 12px 0;

  border-top:1px solid  ${COLORS.neutral_200}
`;

export const FirstLine = styled.div`
    display:flex;
    justify-content: space-between;
    gap: 16px;
    padding: 0 10px;

    
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
  flex-direction: column;
`;

export const Paragraph = styled.p`
    overflow-wrap: anywhere;
`;

export const Container = styled.div`
    display:flex;
`;

export const FirstColumn = styled.div`
    display:flex;
`;
export const SecondColumn = styled.div`
    display:flex;
    flex-direction:row;
    gap:8px;
`;

export const AttachmentsContainer = styled.div`
    display:flex;
    flex-direction: column;
    gap:8px;
    margin-top:8px;
`;

export const First = styled.div`
    display:flex;
`;
export const Second = styled.div`
        flex-grow: 1;
    display:flex;
    flex-direction:column;
    padding: 12px 16px 12px 6px;
`;

export const ShowButton = styled(Button)`
    align-self:start;
`;

export const DateTime = styled(ComponentS)`
    margin-top:1px;
`;




export const HtmlContent = styled.div`
    font-size: 16px;

    p {
        font-size: 16px;
    }

    p.acknowledgement {
        display: none;
    }
`;

export const PushToRight = styled.div`
    display: flex;
    justify-content: end;
`;

export const NoPreviewImg = styled.img`
    width: 16px;
    margin-right:16px;
`;

export const Tooltip = styled(TooltipWrapper)`
    ${BREAKPOINTS.MEDIUM}{
        width: max-content;
    }
    width: 220px;
`;


interface ExpandableProps {
    expanded: boolean;
    isOverflowY: boolean;
}

export const Expandable = styled.div<ExpandableProps>`
  ${(props) => (!props.expanded && 'max-height: 128px;')}
  ${(props) => (!props.expanded && 'overflow-y: hidden;')}
  
  ${(props) => (props.isOverflowY && !props.expanded && 'background: -webkit-linear-gradient(top, black, black 50%, #ffffffbf);')}
  ${(props) => (props.isOverflowY && !props.expanded && 'background: -o-linear-gradient(bottom, black, black 50%, #ffffffbf);')}
  ${(props) => (props.isOverflowY && !props.expanded && 'background: -moz-linear-gradient(bottom, black, black 50%, #ffffffbf);')}
  ${(props) => (props.isOverflowY && !props.expanded && 'background: linear-gradient(to bottom, black, black 50%, #ffffffbf); ')}
  ${(props) => (props.isOverflowY && !props.expanded && '-webkit-background-clip: text;')}
//   ${(props) => (props.isOverflowY && !props.expanded && '-webkit-text-fill-color: transparent;')}
`;

type CaseEmailProps = {
    emailData: CaseEmailDto;
    openAttachment?: (attachment: AttachmentDto) => void;
    downloadAttachment?: (attachment: AttachmentDto) => void;
};

const EmailContent = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, null)}
  padding: 0 10px 20px 10px;
  
`;

const CaseEmail = ({ emailData, openAttachment, downloadAttachment }: CaseEmailProps) => {
    const { t } = useTranslation('Cases');

    const replaceTable = (htmlString: string) => {
        // Create a temporary DOM element
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        
        // Find tables containing the specific text
        const tables = doc.querySelectorAll('table');
        tables?.forEach(table => {
            if (table.textContent?.includes("You don't often get email")) {
                // Remove the table
                table.remove();
            }
        });
        
        // Return the modified HTML string
        return doc.documentElement.outerHTML;
    }
    
    const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isOverflowY, setIsOverflowY] = useState<boolean>(false)
    const refOuter = React.useRef<HTMLDivElement>(null)
    const refInner = React.useRef<HTMLDivElement>(null)

    const isHtml = !!emailData.htmlBody;
    const htmlContent = replaceTable( dompurify.sanitize(emailData.htmlBody ?? '')
                            .replaceAll('<p><br></p>', '')
                            .replaceAll('<img', '<img onerror="this.remove();" ')
                            .replace(/<img[^>]*src="[^"]*sendgrid[^"]*"[^>]*>/gi, '')
                            .replaceAll('white-space:nowrap;',  '')
                            .replaceAll('&nbsp;',  ' '));
                            
    useLayoutEffect(() => {
        const { current: outer } = refOuter;
        const { current: inner } = refOuter;

        if (!!outer && !!inner) {
            const hasOverflowY = inner.scrollHeight > outer.clientHeight
            if (hasOverflowY !== isOverflowY) {
                setIsOverflowY(hasOverflowY)
            }
        }
    }, [emailData, expanded, refOuter, refInner])

    const getInitials = (fromName?: string) => {
        if (!fromName) return "";
        if (fromName.indexOf('@') >= 0) return "";

        const nameParts = fromName.split(" ");

        if (nameParts.length == 1) return nameParts[0][0];

        return nameParts[0][0] + nameParts[1][0];
    };



    return (
        <EmailCard >
            <Container>
                <First>
                    <CreateIcon>
                        <ProfileButton icon={<SystemIcons.User size="36px" />} onClick={() => {}} initials={getInitials(emailData.fromName)} />
                    </CreateIcon>
                </First>
                <Second>
                    <FirstLine>
                        <FirstColumn>
                            <ComponentM textStyle={ComponentTextStyle.Bold}>{emailData.fromName ?? emailData.fromAddress }</ComponentM>
                        </FirstColumn>
                        <SecondColumn>
                            { emailData.attachments.length > 0 && !emailData.justCreatedWithFiles &&
                                <Tag icon={<SystemIcons.Attachment />} />
                            }
                            { emailData.justCreatedWithFiles && 
                                <LoadingIndicator size={Size.Small} />
                                
                            }
                            { !isNaN(new Date(emailData.messageDate).getTime()) &&
                                <DateTime textStyle={ComponentTextStyle.Regular} color={COLORS.neutral_600}>
                                    {moment(emailData.messageDate).format('ll LT')}
                                </DateTime>
                            }
                        </SecondColumn>
                    </FirstLine>
                    
                    <Expandable ref={refOuter} expanded={expanded} isOverflowY={isOverflowY}>
                        <EmailContent ref={refInner}>
                            {isHtml && htmlContent &&
                                <HtmlContent 
                                    dangerouslySetInnerHTML={{ __html: htmlContent
                                        .replace('cellpadding:0;border:0;cellspacing:0;display:table;width:100%;table-layout:fixed;border-collapse:seperate;float:none;','cellpadding:0;border:0;cellspacing:0;display:none;width:100%;table-layout:fixed;border-collapse:seperate;float:none;')
                                        .replace(/<table[^>]*>(?:(?!<\/table>).)*You don't often get email(?:(?!<\/table>).)*<\/table>/g, '') ?? ''
                                     }} />
                            }
                            
                            {!isHtml && emailData.textBody &&
                                emailData.textBody?.split(/\r?\n/).filter(x => x).map(paragraph =>
                                    <Paragraph >{paragraph}</Paragraph>
                                )}

                            { emailData?.attachments?.length > 0 &&
                                <>
                                    <PushToRight>
                                        <Tooltip
                                            position={ "bottom" }
                                            align={"end"}

                                            withArrow={true}
                                            label={"We’re actively working on enabling previews for attachments. Stay tuned!"}
                                            >
                                            <SystemIcons.Information />
                                        </Tooltip>
                                    </PushToRight>
                                    <AttachmentsContainer>
                                        { emailData?.attachments?.map((a, i) =>
                                            <HorizontalCard 
                                                key={i}
                                                variant="elevated"
                                                title={a.fileName} 
                                                description={a.fileExtension} 
                                                actions={a.uploading ? [{
                                                        componentType: "custom",
                                                        content: <LoadingIndicator size={Size.Medium} />
                                                    }] : [
                                                        {
                                                        componentType: 'icon',
                                                            action: () => downloadAttachment!(a),
                                                            icon: <SystemIcons.Download color={COLORS.primary_600} />
                                                        }
                                                ]}
                                                action={ a.uploading ? undefined : () =>  openAttachment!(a)} 
                                                />
                                        )}
                                    </AttachmentsContainer>
                                </>
                            }
                        </EmailContent>
                    </Expandable>

                    {isOverflowY &&
                        <ShowButton
                            onClick={() => {
                                //@ts-ignore
                                posthog?.capture('CaseEmail Expand');
                                setExpanded(true)
                            }}
                            variant="tertiary"
                            size={Size.Small}>{t('Show more')}</ShowButton>
                    }
                    {expanded &&
                        <ShowButton
                            onClick={() => {
                                //@ts-ignore
                                posthog.capture?.('CaseEmail Collaps');
                                setExpanded(false)
                            }}
                            variant="tertiary"
                            size={Size.Small}>{t('Show less')}</ShowButton>
                    }
                </Second>
            </Container>
        </EmailCard>
    );
}

export default CaseEmail;

